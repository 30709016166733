import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

export default function ScannerDialog(props) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = (value) => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} >
    
      <DialogTitle>Download Scanner Application</DialogTitle>
      <List sx={{ pt: 0}}>
      <ListItem disableGutters >
        <ListItemButton  onClick={() => {handleListItemClick(); window.open('https://play.google.com/store/apps/details?id=com.hummingbirddev.auth0signin', '_blank')}} >
        <img src='/GooglePlayBadge.png' style={{padding: 3, margin: 'auto'}} width={'80%'}  />
        </ListItemButton>
      </ListItem>
      <ListItem disableGutters >
        <ListItemButton onClick={() => handleListItemClick()} disabled>
          <img src='/AppleStoreBadge.svg' style={{padding: 3, margin: 'auto'}} width={'80%'}/>
        </ListItemButton>
      </ListItem>
      </List>
    </Dialog>
  );
}
