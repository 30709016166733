import React from "react";
import { useEffect, useState } from "react";
import logo from "../../img/logo_light.svg";
import { Link, withRouter } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ContestEntry from "../../portal/components/ContestEntry.js";
import { useLocation } from "react-router-dom";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  adaptV4Theme,
} from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ScannerDialog from "./ScannerApplicationLinks";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Stack } from "@mui/material";
import { Box } from "@material-ui/core";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function NavBar() {
  const darkTheme = createTheme(
    adaptV4Theme({
      palette: {
        mode: "dark",
      },
    })
  );
  const [navOpacity, setNavOpacity] = React.useState("nav-colored");
  const location = useLocation();

  const [openAppDialog, setOpenAppDialog] = React.useState(false);

  const handleClickOpen = () => {
    setOpenAppDialog(true);
  };

  const handleClose = (value) => {
    setOpenAppDialog(false);
  };

  // useEffect(() => {
  //     //listens for any route changes
  //     this.props.history.listen(() => {
  //         setState({
  //             currentRoute: window.location.pathname
  //         });
  //         // if route has been changed to about refresh the page
  //         // so that the facebook plugin will load
  //         if (window.location.pathname === '/about') {
  //             window.location.reload();
  //         })
  //     }, [location])

  //listen for scroll event
  // useEffect(() => {
  //       window.addEventListener("scroll", setNavOpacity('nav-colored'), true);
  //       return () => {
  //         window.removeEventListener("scroll", setNavOpacity('nav-colored'), true);
  //       };
  // }, [])

  let links = {
    logo: (
      <Link to={process.env.PUBLIC_URL + "/"}>
        <img src={logo} alt="go to homepage" />
      </Link>
    ),
    schedule: (
      <a href="https://outlook.office365.com/owa/calendar/DoubleDFinancial@doubledfinancial.com/bookings/">
        schedule online
      </a>
    ),
    services: <Link href="/#services-top">services</Link>,
    contact: <Link href="/#contact-top">contact</Link>,
    about: <Link to={process.env.PUBLIC_URL + "/about"}>about</Link>,
    upload: <PortalLink />,
    scannerApp: (
      <Link
        onClick={() => {
          handleClickOpen();
        }}
      >
        scanner download
      </Link>
    ),
    portal: <PortalLink />,

    // contest:(<a onClick={handleClickOpen} style={{'cursor': 'pointer', 'color': 'white'}}>contest entry</a>),
  };

  // if the current route doesn't have services and contact sections
  if (location !== "/home/" && location !== "/home/info") {
    links.services = (
      <Link to={process.env.PUBLIC_URL + "/info"}>services</Link>
    );
    links.contact = <Link to={process.env.PUBLIC_URL + "/info"}>contact</Link>;
  }

  return (
    <div>
      <div>
        <nav className={navOpacity} style={{ position: "fixed" }}>
          {links.logo}
          <ul className="desktop-nav-links">
            <li>{links.scannerApp}</li>
            <li>{links.contest}</li>
            <li>{links.schedule}</li>
            <li>{links.services}</li>
            <li>{links.about}</li>
            <li>{links.contact}</li>

            <li>{links.portal}</li>
          </ul>
          <Box
            sx={{ display: { xs: "block", sm: "none" } }}
            marginRight={"20px"}
          >
            <MobileMenu links={links} handleClickOpen={handleClickOpen} />
          </Box>
        </nav>
      </div>
      {/* <div>
            <Dialog open={open} onClose={handleClose}>
                <ContestEntry/>
            </Dialog>
            </div> */}
      <ScannerDialog open={openAppDialog} onClose={handleClose} />
    </div>
  );
}

class DropdownMenu extends React.Component {
  render() {
    return (
      <div className="dropdown">
        <button className="dropbtn">Menu</button>
        <div className="dropdown-content">
          {this.props.links.services}
          {this.props.links.about}
          {this.props.links.contact}
          {this.props.links.upload}
          {this.props.links.schedule}
          {this.props.links.contest}
        </div>
      </div>
    );
  }
}

function MobileMenu(props) {
  const { handleClickOpen } = { ...props };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  let menuItems = {
    schedule: (
      <MenuItem
        onClick={() => {
          handleClose();
          window.location.href = "https://outlook.office365.com/owa/calendar/DoubleDFinancial@doubledfinancial.com/bookings/";
        }}
      >
        Schedule Online
      </MenuItem>
    ),
    services: (
      <MenuItem
        onClick={() => {
          handleClose();
          window.location.href =process.env.PUBLIC_URL + "/#services-top";
        }}
      >
        Services
      </MenuItem>
    ),
    contact: (
      <MenuItem
        onClick={() => {
          handleClose();
          window.location.href =process.env.PUBLIC_URL + "/#contact-top";
        }}
      >
        Contact
      </MenuItem>
    ),
    about: (
      <MenuItem
        onClick={() => {
          handleClose();
          window.location.href = process.env.PUBLIC_URL + "/about";
        }}
      >
        About
      </MenuItem>
    ),
    scannerApp: (
      <MenuItem
        onClick={() => {
          handleClose();
          handleClickOpen();
        }}
      >
        Scanner Application
      </MenuItem>
    ),
    portal: <PortalLink />,

    // contest:(<a onClick={handleClickOpen} style={{'cursor': 'pointer', 'color': 'white'}}>contest entry</a>),
  };

  // if the current route doesn't have services and contact sections
  // if (location !== "/home/" && location !== "/home/info") {
  //     menuItems.services = (<Link to={process.env.PUBLIC_URL + "/info"}>services</Link>);
  //     menuItems.contact = (<Link to={process.env.PUBLIC_URL + "/info"}>contact</Link>);
  // }

  return (
    <div>
      {/* <button className="dropbtn" onClick={handleClick}>Menu</button> */}
      <Button
        id="basic-button"
        //aria-controls={open ? 'mobile-menu' : undefined}
        //aria-haspopup="true"
        //aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant={anchorEl ? "outlined" : "contained"}
        sx={
          anchorEl
            ? {
                backgroundColor: "#000 !important",
                borderColor: "#fff !important",
                fillColor: "#000",
                color: "#fff",
                paddingX: "15px",
                paddingY: "15px",
              }
            : { backgroundColor: "#fff", paddingX: "15px", paddingY: "15px" }
        }
        //className='dropbtn'
      >
        Menu
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "Mobile Menu",
        }}
      >
        <Stack direction={"column"} px={3}>
          {menuItems.about}
          {menuItems.contact}
          {menuItems.services}
          {menuItems.schedule}
          {menuItems.scannerApp}
          <MenuItem onClick={handleClose}>{menuItems.portal}</MenuItem>
        </Stack>
      </Menu>
    </div>
  );
}
const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <a className="portal-link" tabIndex="0" onClick={() => loginWithRedirect()}>
      Login
    </a>
  );
};

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <a
      className="portal-link"
      tabIndex="0"
      onClick={() => logout({ returnTo: window.location.origin })}
    >
      Logout
    </a>
  );
};

const PortalLink = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }
  if (isAuthenticated) {
    if (window.location.pathname === "/portal") {
      return <LogoutButton />;
    }
    return (
      <div>
        <Link
          reloadDocument
          className="portal-link"
          tabindex="0"
          to={"/portal"}
        >
          account
        </Link>
      </div>
    );
  } else {
    return <LoginButton />;
  }
};

export default NavBar;
